export const CONSTANTS = {
    color: {
        white: 'white',
        primary: 'primary'
    },
    sectionType: {
        dark: 'dark',
        light: 'light',
    },
    section: {
        app: {
            id: "app", 
            name: "Apka"
        },
        individual: {
            id: "individual", 
            name: "Klient Indywidualny"
        },
        business: { 
            id: "business", 
            name: "Klient Biznesowy" 
        },
        chargers: { 
            id: "chargers", 
            name: "Infrastruktura" 
        },
        investment: { 
            id: "investment", 
            name: "Relacje Inwestorskie" 
        },
        contact: { 
            id: "contact", 
            name: "Kontakt" 
        },
    },
}